:root {
  --main-background-color: rgb(43, 45, 58);
  --secondary-background-color: rgb(60, 62, 73, 0.5);
  --navbar-background-color: rgb(60, 62, 73, 0.5);
}

.wrapper-header-box {
  background-color: var(--main-background-color);
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  left: 0;
  transform-origin: right;
  animation: introtext 2s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

a {
  color: unset;
}

@keyframes introtext {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

.body {
  margin-top: 74px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.navbar {
  margin: 10px;
  background-color: var(--navbar-background-color);
  width: calc(100% - 45px);
  position: fixed;
  top: 0;
  height: 30px;
  display: flex;
  align-items: center;
  border: 1px solid rgb(97, 95, 109);
  box-shadow: 0 -10px 30px 2px #22202b;
  border-radius: 4px;
  backdrop-filter: blur(30px);
  padding: 10px;
  gap: 10px;
  z-index: 9;
}

.icon {
  height: 100%;
  fill: white;
  width: 60px;
}

.tab {
  height: 100%;
  display: flex;
  border-radius: 2px;
  width: 33%;
  display: table-cell;
  font-weight: bold;
  font-size: 14px;
  border: 0;
  cursor: pointer;
  transition: ease 0.5s;
}

.tab:hover, .tab:focus {
  scale: 110%;
}

.tab:active {
  scale: 100%;
  filter: brightness(90%);
}

.tabbar {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 100%;
}

.wrapper {
  overflow: hidden;
  width: calc(100%, 100px);
  background-color: var(--secondary-background-color);
  height: calc(100vh - 78px);
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.dynamic-wrapper {
  overflow: hidden;
  width: calc(100%, 100px);
  height: 100%;
  background-color: var(--secondary-background-color);
  padding: 0 50px 50px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wrapper-intro-header {
  font-size: 100px;
  color: white;
  margin: 0;
  position: relative;
  width: fit-content;
}

.wrapper-intro-description {
  font-size: 70px;
  opacity: 0.5;
  color: white;
  position: relative;
  width: fit-content;
  margin: 0;
}

.background-image {
  position: absolute;
  width: 1000px;
  right: 10px;
  transform: rotate(-10deg);
  fill: rgb(109, 109, 126);
  opacity: 0.5;z-index: -10;
}

.spacer {
  width: 100%;
  height: 100%;
}

.contact-wrapper {
  width: 100%;
  display: flex;
  margin-top: 10px;
  gap: 20px;
  height: 50px;
}

.contact-button {
  height: 100%;
  fill: white;
  padding: 10px;
  border-radius: 50%;
  transition: 0.3s;
  filter: brightness(80%);
  scale: 90%;
  transition: ease 0.5s;
  cursor: pointer;
}

.contact-button:hover, .contact-button:focus {
  background-color: rgb(85, 87, 103);
  filter: brightness(100%);
  scale: 100%;
}

.contact-button svg {
  width: 50px;
  height: 50px;
}

.contact-button:active {
  scale: 90%;
  filter: brightness(90%);
}

.language-wrapper {
  display: grid;
  gap: 10px;
  grid-template-areas: 'box box box'
                       'box box box';
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
}

.language-box {
  background-color: rgb(85, 87, 103);
  display: flex;
  align-items: center;
  padding: 10px 0px 10px 20px;
  display: flex;
  gap: 20px;
  color: white;
  border-radius: 5px;
  grid-area: 'box';
}

.language-box svg {
  width: 50px;
  fill: white;
}

.wrapper-inside {
  width: 100%;
  display: flex;
  justify-content: center;
}

.wrapper-title {
  color: white;
  font-size: 40px;
  text-align: center;
}

.project-wrapper {
  display: flex;
  gap: 30px;
}

.project-box {
  background-color: rgb(85, 87, 103);
  border-radius: 10px;
  overflow: hidden;
  width: 300px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.project-box-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.project-box-title {
  color: white;
  font-size: 30px;
  font-weight: bold;
}

.project-box-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;
}

.project-box-tags-box {
  display: flex;
  gap: 10px;
}

.project-box-tag {
  background-color: rgba(137, 148, 188, 0.287);
  padding: 5px 10px 5px 10px;
  color: white;
  border-radius: 6px;
}

.project-box-class-buttons-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.project-box-class-button, .project-box-class-buttons-box a {
  width: 100%;
  cursor: pointer;
}

.project-box-class-button {
  height: 40px;
  border: 0;
  background-color: rgba(137, 148, 188, 0.287);
  color: white;
  font-weight: bold;
  border-radius: 5px;
  font-size: 15px;
  transition: ease 0.5s;
}

.project-box-class-button:hover {
  scale: 110%;
  background-color: rgba(162, 171, 205, 0.287);
  box-shadow: 0 7px 10px 2px rgba(94, 102, 129, 0.287);
}

.project-box-class-button:active {
  scale: 100%;
  filter: brightness(90%);
}

.project-box-description {
  color: white;
  flex-grow: 1;
}

.project-box-price {
  position: absolute;
  background-color: rgba(162, 171, 205, 0.287);
  color: white;
  padding: 10px;
  border-radius: 5px;
  top: 20px;
  font-weight: bold;
  left: 20px;
  backdrop-filter: blur(20px);
}

.scrolled-into-view {
  scroll-margin-top: 100px;
  transform-origin: top;
}

@keyframes secondview {
  0% { scale: 90%; opacity: 0;}
  100% { scale: 100%; opacity: 1;}
}

.scroll-button {
  width: 100%;
  height: 70px;
  position: absolute;
  bottom: 90px;
  left: 0;
  animation: floating 3s ease-in-out infinite;
  font-size: 60px;
  color: white;
  background: none;
  border: 0;
  cursor: pointer;
}

.scroll-button svg {
  fill: white;
  filter: drop-shadow(0px 10px 10px rgba(53, 59, 80, 0.686));
}

.cls-1 {
  fill: none;
}

.footer {
  background-color: var(--secondary-background-color);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  font-weight: bold;
  position: relative;
  overflow: hidden;
}

.dynamic-wrapper form {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1em;
}

.dynamic-wrapper label {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.dynamic-wrapper span {
  color: rgb(85, 87, 103);
  font-weight: bold;
  max-width: min(30vw, 100%);
}

.dynamic-wrapper input,
.dynamic-wrapper textarea {
  border: 0;
  background-color: rgb(85, 87, 103);
  min-width: 30vw;
  max-width: min(30vw, 100%);
  padding: 10px;
  border-radius: 5px;
  color: white;
  font-size: 17px;
}

.dynamic-wrapper input:focus,
.dynamic-wrapper textarea:focus {
  outline: none;
  box-shadow: 0px 0px 0px 2px rgba(162, 171, 205, 0.699);
}

.dynamic-wrapper input::placeholder,
.dynamic-wrapper textarea::placeholder {
  color: rgb(142, 149, 165);
  font-weight: bold;
}

.dynamic-wrapper form button {
  background-color: rgb(85, 87, 103);
  border-radius: 5px;
  color: white;
  border: 0;
  padding: 10px 50px;
  font-weight: bold;
  cursor: pointer;
  transition: ease 0.5s;
}

.dynamic-wrapper form button:hover,
.dynamic-wrapper form button:focus {
  scale: 110%;
  filter: brightness(110%);
}

.dynamic-wrapper form button:active {
  scale: 100%;
  filter: brightness(90%);
}

::-webkit-resizer {
  border-width: 3px;
  border-style: solid;
  border-color: transparent rgb(142, 149, 165) rgb(142, 149, 165) transparent;
  opacity: 0.5;
}

::-webkit-scrollbar {
  width: 8px;
  border-radius: 100vw;
  margin: 90px;
}

::-webkit-scrollbar-track {
  background-color: var(--main-background-color);
  border-radius: 100vw;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgb(87, 89, 100);
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }

  50% {
    transform: translate(0, 15px);
  }

  100% {
    transform: translate(0, -0px);
  }
}

@media only screen and (max-width: 600px) {
  .language-wrapper {
    display: flex;
    flex-direction: column;
  }

  .wrapper-intro-header {
    font-size: 15vw;
    text-align: center;
    width: 100%;
  }

  .wrapper-intro-description {
    font-size: 10vw;
    text-align: center;
  }

  .wrapper {
    height: 700px;
    padding-bottom: 40px;
  }

  .background-image {
    width: 150%;
    translate: 140px;
  }

  .contact-wrapper {
    gap: 0px;
    justify-content: center;
    z-index: 1;
  }

  .tabbar {
    display: none;
  }

  .spacer {
    display: none;
  }

  .navbar {
    justify-content: center;
  }

  .project-box {
    width: 100%;
  }

  .wrapper-header-box {
    display: none;
  }

  .dynamic-wrapper label {
    width: 100%;
  }

  .dynamic-wrapper input,
  .dynamic-wrapper textarea {
    min-width: 100%;
    font-size: 100%;
  }

  .dynamic-wrapper form button {
    padding: 20px 60px;
    font-size: 100%;
  }

  .dynamic-wrapper span {
    font-size: 150%;
  }
}

@media only screen and (max-width: 1000px) {
  .project-wrapper {
    flex-direction: column;
    height: 100%;
  }
}

@media (prefers-reduced-motion: reduce) {
  *,
  ::before,
  ::after {
    animation-delay: -1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
    background-attachment: initial !important;
    scroll-behavior: auto !important;
    transition-duration: 0s !important;
    transition-delay: 0s !important;
  }
}